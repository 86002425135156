import React from "react";

import {
  TextField,
  Datagrid,
  Edit,
  SimpleForm,
  ReferenceField,
  TextInput,
  ReferenceManyField,
} from "react-admin";

import { NameField } from "../components/NameField";
import { AssignmentExerciseField } from "../components/AssignmentExerciseField";
import { ListWithFilter } from "../components/ListWithFilter";

const teamsList = props => (
  <ListWithFilter
    filters={[
      { label: "Team ID", source: "id" },
      { label: "Leader ID", source: "leader_id" },
      { label: "Assignment Exercise ID", source: "assignment_exercise_id" },
    ]}
  >
    <Datagrid>
      <TextField source="id" />
      <ReferenceField label="Leader Name" source="leader_id" reference="users">
        <NameField />
      </ReferenceField>
      <TextField source="assignment_exercise_id" />
      <ReferenceField
        label="Module/Exercise"
        source="assignment_exercise_id"
        reference="assignment_exercises"
        link={false}
      >
        <AssignmentExerciseField />
      </ReferenceField>
      <ReferenceManyField label="Team Members" reference="team_users" target="team_id">
        <Datagrid header={() => null}>
          <ReferenceField source="user_id" reference="users">
            <NameField />
          </ReferenceField>
        </Datagrid>
      </ReferenceManyField>
      <TextField source="created_at" />
    </Datagrid>
  </ListWithFilter>
);

const teamsEdit = props => (
  <Edit>
    <SimpleForm>
      <TextInput source="leader_id" sx={{ width: "350px" }} />
      <TextInput source="assignment_exercise_id" />
    </SimpleForm>
  </Edit>
);

const views = {
  edit: teamsEdit,
  list: teamsList,
};

export default views;
