export default function dataProvider(type, resource, params, provider) {
  if (type === "CREATE") {
    return provider("CREATE", "users", params).then(createdUser => {
      const userId = createdUser.data.id;
      provider("CREATE", "results", {
        data: { user_id: userId, cohort_id: params.data.cohort_id },
      });
      return createdUser;
    });
  }

  // fallback to the default implementation
  return provider(type, resource, params);
}
