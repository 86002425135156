import React from "react";

import { FunctionField } from "react-admin";

export const NameField = props => {
  return (
    <FunctionField
      {...props}
      label="Name"
      render={record => `${record.first_name} ${record.last_name}\n`}
    />
  );
};

export default NameField;
