import React from "react";
import { TextField, Datagrid, Edit, SimpleForm, TextInput, Create, EditButton } from "react-admin";
import { ListWithFilter } from "../components/ListWithFilter";

const WorkshopResourceList = props => (
  <ListWithFilter
    filters={[
      { label: "type", source: "type" },
      { label: "team_id", source: "team_id" },
      { label: "session_id", source: "session_id" },
    ]}
  >
    <Datagrid>
      <EditButton />
      <TextField source="id" />
      <TextField source="date_created" />
      <TextField source="type" />

      <TextField source="team_id" />
      <TextField source="index" />
      <TextField source="session_id" />
    </Datagrid>
  </ListWithFilter>
);

const WorkshopResourceEdit = props => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput source="date_created" />
      <TextInput source="type" />

      <TextInput multiline={true} source="link_url" />
      <TextInput multiline={true} source="link_text" />
      <TextInput source="team_id" />
      <TextInput source="index" />
      <TextInput source="session_id" />
    </SimpleForm>
  </Edit>
);

const WorkshopResourceCreate = props => {
  return (
    <Create>
      <SimpleForm>
        <TextInput source="type" />
        <TextInput source="link_url" />
        <TextInput source="link_text" />
        <TextInput source="team_id" />
        <TextInput source="index" />
        <TextInput source="session_id" />
      </SimpleForm>
    </Create>
  );
};

const views = {
  list: WorkshopResourceList,
  edit: WorkshopResourceEdit,
  create: WorkshopResourceCreate,
};

export default views;
