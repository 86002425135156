export default function usersDataProvider(type, resource, params, provider) {
  if (type === "CREATE") {
    return provider("CREATE", "users", {
      data: {
        first_name: params.data.first_name,
        last_name: params.data.last_name,
        email: params.data.email,
        is_admin_role: false,
        is_grader_role: true,
      },
    }).then(createdUser => {
      // add the grader to the demo_greekhorse cohort
      provider("CREATE", "results", {
        data: { user_id: createdUser.data.id, cohort_id: "demo_greekhorse" },
      });

      return provider("CREATE", "graders", {
        data: {
          user_id: createdUser.data.id,
          allowed_cohorts: params.data.allowed_cohorts,
        },
      });
    });
  }

  // if (type === "DELETE_MANY") {
  //   return provider("GET_MANY", "graders", {
  //     ids: params.ids,
  //   }).then(graders => {
  //     const userIds = graders.data.map(grader => grader.user_id);
  //     // delete results by using the user_id
  //     return Promise.all(
  //       userIds.map(userId => provider("DELETE", "results", { user_id: userId }))
  //     ).then(() =>
  //       provider("DELETE_MANY", "users", {
  //         ids: userIds,
  //         // THIS PARAMETER DOESN'T REQUIRE A DATA PROP
  //       })
  //     );
  //   });
  // }

  // if (type === "DELETE") {
  //   provider("DELETE", "results", {
  //     user_id: params.previousData.user_id,
  //   });
  //   return provider("DELETE", "users", {
  //     id: params.previousData.user_id,
  //   });
  // }

  // fallback to the default implementation
  return provider(type, resource, params);
}
