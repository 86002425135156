import {
  List,
  TextField,
  Datagrid,
  Edit,
  EditButton,
  SimpleForm,
  TextInput,
  Create,
  ArrayInput,
  SimpleFormIterator,
  useRecordContext,
} from "react-admin";

const FormattedListField = props => {
  const record = useRecordContext(props);

  return <span>{record.allowed_domains ? record.allowed_domains.join(", ") : null}</span>;
};

FormattedListField.defaultProps = { label: "Allowed domains" };

const CompaniesList = props => (
  <List sort={{ field: "created_at", order: "DESC" }}>
    <Datagrid>
      <EditButton />
      <TextField source="id" />
      <TextField source="name" />
      <FormattedListField source="allowed_domains" />
    </Datagrid>
  </List>
);

const CompaniesEdit = props => (
  <Edit
    transform={data => {
      data["allowed_domains"] =
        "{" + (data["allowed_domains"] ? data["allowed_domains"].join(",") : "") + "}";
      return data;
    }}
  >
    <SimpleForm>
      <TextInput source="name" />
      <ArrayInput source="allowed_domains">
        <SimpleFormIterator>
          <TextInput />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);
const CompaniesCreate = props => (
  <Create
    transform={data => {
      data["allowed_domains"] =
        "{" + (data["allowed_domains"] ? data["allowed_domains"].join(",") : "") + "}";
      return data;
    }}
  >
    <SimpleForm>
      <TextInput source="name" />
      <ArrayInput source="allowed_domains">
        <SimpleFormIterator>
          <TextInput />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

const views = {
  create: CompaniesCreate,
  edit: CompaniesEdit,
  list: CompaniesList,
};
export default views;
