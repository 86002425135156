import PropTypes from "prop-types";
import * as React from "react";
import {
  useRecordContext,
  useUpdate,
  useResourceContext,
  useNotify,
  useRefresh,
} from "react-admin";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const InlineOptionField = props => {
  const resource = useResourceContext();
  const refresh = useRefresh();
  const { source, options } = props;
  const [update] = useUpdate();
  const record = useRecordContext();
  const notify = useNotify();

  const [option, setOption] = React.useState(record[source] || "");
  const handleChange = event => {
    setOption(event.target.value);

    update(
      resource || "results",
      {
        id: record.id,
        data: { [source]: event.target.value },
        previousData: record,
      },
      {
        onSuccess: () => {
          notify("Results updated");
          console.log({ [source]: event.target.value });
          refresh();
        },
        onFailure: error => notify("Error: results not updated", "warning"),
      }
    );
  };

  return (
    <Select
      labelId="inline-select-label"
      id="inline-select"
      defaultValue={option}
      onChange={handleChange}
      autoWidth
      sx={{ minWidth: "100px" }}
    >
      {options.map(optionVal => (
        <MenuItem key={optionVal.id} value={optionVal.id}>
          {optionVal.name}
        </MenuItem>
      ))}
      <MenuItem key={"empty"} value={null}>
        Empty - No Value
      </MenuItem>
    </Select>
  );
};

InlineOptionField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default InlineOptionField;
