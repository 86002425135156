import * as React from "react";
import { Fragment, useState } from "react";
import { Button, useCreate, useNotify, useUnselectAll, useListContext } from "react-admin";
import { CohortSelectDialog } from "./QuickResultCreate";

const BulkAddCohortUser = ({ label }) => {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const unselectAll = useUnselectAll("users");
  const [create, { loading }] = useCreate();
  const { selectedIds } = useListContext();

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleSubmit = async values => {
    console.log("Submit vals:", values);
    console.log("Selected IDs:", selectedIds);

    selectedIds.forEach(id => {
      values.user_id = id;
      create(
        "results",
        { data: values },
        {
          onSuccess: () => {
            unselectAll();
            setOpen(false);
            notify("User(s) added to cohort");
          },
          onError: () => notify("An error ocurred while adding user to cohort", "warning"),
        }
      );
    });
  };

  const dialogProps = {
    handleSubmit,
    handleCloseClick: handleDialogClose,
    loading,
    showDialog: open,
  };

  return (
    <Fragment>
      <Button label={label} onClick={handleClick} />
      <CohortSelectDialog {...dialogProps} />
    </Fragment>
  );
};

export default BulkAddCohortUser;
