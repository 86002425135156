import { Layout } from "react-admin";
import React from "react";

const CustomLayout = props => {
  return (
    <React.Fragment>
      <Layout {...props} />
      <a
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          zIndex: 2,
          padding: "10px",
        }}
        href={`${window.location.href.split("?")[0]}?filter={}`}
      >
        reset page filters
      </a>
    </React.Fragment>
  );
};

export default CustomLayout;
