import React from "react";
import {
  TextField,
  Datagrid,
  DateField,
  Edit,
  SimpleForm,
  TextInput,
  Show,
  SimpleShowLayout,
  useRecordContext,
  TopToolbar,
  EditButton,
  Button,
  ReferenceManyField,
  ReferenceField,
} from "react-admin";
import { ListWithFilter } from "../components/ListWithFilter";

import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { Link } from "react-router-dom";
import { NameField } from "../components/NameField";

const AddNewUserButton = () => {
  const record = useRecordContext();
  return (
    <Button
      component={Link}
      to={{
        pathname: "/skill_workshop_session_team_users/create",
        state: { team_id: record.id },
      }}
      label="Add a user"
    >
      <ChatBubbleIcon />
    </Button>
  );
};
// const PostQuickPreviewButton = ({ id }) => {
//   const [showPanel, setShowPanel] = useState(false);
//   const classes = useStyles();
//   const { data } = useGetOne("posts", id);

//   const handleClick = () => {
//     setShowPanel(true);
//   };

//   const handleCloseClick = () => {
//     setShowPanel(false);
//   };

//   return (
//     <>
//       <Button onClick={handleClick} label="ra.action.show">
//         <IconImageEye />
//       </Button>
//       <Drawer anchor="right" open={showPanel} onClose={handleCloseClick}>
//         <div>
//           <Button label="Close" onClick={handleCloseClick}>
//             <IconKeyboardArrowRight />
//           </Button>
//         </div>
//         <SimpleShowLayout record={data} basePath="/posts" resource="posts">
//           <TextField source="id" />
//           <TextField source="title" className={classes.field} />
//           <TextField source="teaser" className={classes.field} />
//         </SimpleShowLayout>
//       </Drawer>
//     </>
//   );
// };

// function PostQuickCreateButton({ onChange }) {
//   const [showDialog, setShowDialog] = useState(false);
//   const [create, { loading }] = useCreate("skill_workshop_session_team_users");
//   const notify = useNotify();
//   const form = useForm();

//   const handleClick = () => {
//     setShowDialog(true);
//   };

//   const handleCloseClick = () => {
//     setShowDialog(false);
//   };

//   const handleSubmit = async values => {
//     create(
//       { payload: { data: values } },
//       {
//         onSuccess: ({ data }) => {
//           setShowDialog(false);
//           // Update the comment form to target the newly created post
//           // Updating the ReferenceInput value will force it to reload the available posts
//           form.change("post_id", data.id);
//           onChange();
//         },
//         onFailure: ({ error }) => {
//           notify(error.message, "error");
//         },
//       }
//     );
//   };

//   return (
//     <>
//       <Button onClick={handleClick} label="ra.action.create">
//         <IconContentAdd />
//       </Button>
//       <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Create post">
//         <DialogTitle>Create post</DialogTitle>

//         <FormWithRedirect
//           resource="users"
//           save={handleSubmit}
//           render={({ handleSubmitWithRedirect, pristine, saving }) => (
//             <>
//               <DialogContent>
//                 <TextInput source="title" validate={required()} fullWidth />
//                 <TextInput source="teaser" validate={required()} fullWidth />
//               </DialogContent>
//               <DialogActions>
//                 <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading}>
//                   <IconCancel />
//                 </Button>
//                 <SaveButton
//                   handleSubmitWithRedirect={handleSubmitWithRedirect}
//                   pristine={pristine}
//                   saving={saving}
//                   disabled={loading}
//                 />
//               </DialogActions>
//             </>
//           )}
//         />
//       </Dialog>
//     </>
//   );
// }

const TeamList = props => (
  <ListWithFilter
    filters={[
      { source: "id", label: "id" },
      { source: "title", label: "title" },
      { source: "session_id", label: "session_id" },
    ]}
  >
    <Datagrid
      sx={
        {
          // "& .column-user_id": { minWidth: "150px" },
        }
      }
    >
      <EditButton />
      {/* <ShowButton label="Show details" /> */}
      <TextField source="id" />
      <TextField source="title" />
      <ReferenceManyField
        label="Team Members"
        reference="skill_workshop_session_team_users"
        target="team_id"
      >
        <Datagrid isRowSelectable={() => false} header={() => null}>
          <ReferenceField source="user_id" reference="users">
            <NameField />
          </ReferenceField>
        </Datagrid>
      </ReferenceManyField>
      <TextField source="session_id" />
      <DateField source="date_created" />
    </Datagrid>
  </ListWithFilter>
);

const TeamEdit = props => (
  <Edit>
    <SimpleForm>
      {/* <TextInput source="id" /> */}
      <TextInput source="title" />
      <TextInput source="session_id" />
      <ReferenceManyField
        label="Team Members"
        reference="skill_workshop_session_team_users"
        target="team_id"
        sx={{ marginBottom: "20px" }}
      >
        <Datagrid header={() => null}>
          <ReferenceField sx={{ minWidth: "150px" }} source="user_id" reference="users">
            <NameField />
          </ReferenceField>
        </Datagrid>
      </ReferenceManyField>
      <ReferenceManyField
        label="Team Resources"
        reference="skill_workshop_resources"
        target="team_id"
      >
        <Datagrid>
          <TextField source="id" />
          <TextField source="date_created" />
          <TextField source="type" />
          <TextField source="link_url" />
          <TextField source="link_text" />
          <TextField source="team_id" />
          <TextField source="index" />
          <TextField source="session_id" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleForm>
  </Edit>
);

// const TeamCreate = props => (
//   <Create>
//     <SimpleForm>
//       <TextInput source="id" />
//       <TextInput source="title" />
//       <TextInput source="session_id" />
//     </SimpleForm>
//   </Create>
// );

const TeamTitle = () => {
  const record = useRecordContext();
  // the record can be empty while loading
  if (!record) return null;
  return <span>TEAM "{record.title}"</span>;
};

const TeamShow = props => (
  <Show title={<TeamTitle />} actions={<TeamShowActions />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="session_id" />
      <DateField source="date_created" />
      <ReferenceManyField
        label="Team Members"
        reference="skill_workshop_session_team_users"
        target="team_id"
      >
        <Datagrid header={() => null}>
          <ReferenceField source="user_id" reference="users">
            <NameField />
          </ReferenceField>
        </Datagrid>
      </ReferenceManyField>
      <ReferenceManyField
        label="Team Resources"
        reference="skill_workshop_resources"
        target="team_id"
      >
        <Datagrid header={() => null}>
          <Datagrid>
            <TextField source="id" />
            <TextField source="date_created" />
            <TextField source="type" />
            <TextField source="link_url" />
            <TextField source="link_text" />
            <TextField source="team_id" />
            <TextField source="index" />
            <TextField source="session_id" />
          </Datagrid>
        </Datagrid>
      </ReferenceManyField>

      <AddNewUserButton />
      {/* <PostQuickCreateButton /> */}
    </SimpleShowLayout>
  </Show>
);

const TeamShowActions = () => (
  <TopToolbar>
    <EditButton />
  </TopToolbar>
);

const views = {
  list: TeamList,
  edit: TeamEdit,
  // create: TeamCreate,
  show: TeamShow,
};
export default views;
