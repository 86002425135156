import {
  TextField,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  SimpleForm,
  TextInput,
  Create,
  ReferenceField,
  BooleanField,
  BooleanInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import { ListWithFilter } from "../components/ListWithFilter";

const CohortsList = props => (
  <ListWithFilter
    filters={[
      { label: "id", source: "id" },
      { label: "company_id", source: "company_id" },
      { label: "level_id", source: "level_id" },
    ]}
  >
    <Datagrid>
      <EditButton />
      <TextField source="id" />
      <ReferenceField label="Company" source="company_id" reference="companies">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Level_id" source="level_id" reference="levels">
        <TextField source="id" />
      </ReferenceField>
      <DateField source="send_invite_on" />
      <DateField source="retry_start_on" />
      <DateField source="retry_assessment_start_on" />
      <DateField source="retry_end_on" />
      <DateField source="created_at" />
      <BooleanField source="allow_staging" />
    </Datagrid>
  </ListWithFilter>
);

const CohortsEdit = props => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" />
      <ReferenceInput label="Company id" source="company_id" reference="companies" perPage={100}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput label="Level_id" source="level_id" reference="levels" perPage={100}>
        <SelectInput />
      </ReferenceInput>
      <DateInput source="send_invite_on" />
      <DateInput source="retry_start_on" />
      <DateInput source="retry_assessment_start_on" />
      <DateInput source="retry_end_on" />
      <BooleanInput source="allow_staging" />
    </SimpleForm>
  </Edit>
);

const CohortsCreate = props => (
  <Create>
    <SimpleForm>
      <TextInput source="id" />
      <ReferenceInput label="Company id" source="company_id" reference="companies" perPage={100}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput label="Level_id" source="level_id" reference="levels" perPage={100}>
        <SelectInput optionText="id" />
      </ReferenceInput>
      <DateInput source="send_invite_on" />
      <DateInput source="retry_start_on" />
      <DateInput source="retry_assessment_start_on" />
      <DateInput source="retry_end_on" />
      <BooleanInput source="allow_staging" />
    </SimpleForm>
  </Create>
);

const views = {
  create: CohortsCreate,
  list: CohortsList,
  edit: CohortsEdit,
};

export default views;
