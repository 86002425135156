import { List, ExportButton, CreateButton } from "react-admin";
import { Box } from "@mui/material";
import { Filter } from "./Filter";

export const ListWithFilter = ({ children, filters = [], ...props }) => {
  const ListActions = () => (
    <Box
      width="100%"
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        mt: "15px",
      }}
    >
      <Box>
        <Filter filters={filters} />
      </Box>
      <Box>
        <CreateButton />
        <ExportButton maxResults={5000} />
      </Box>
    </Box>
  );

  return (
    <List
      {...props}
      actions={<ListActions />}
      sx={{
        "& .RaList-main > .MuiToolbar-root": {
          justifyContent: "start",
        },
      }}
    >
      {children}
    </List>
  );
};
