import * as React from "react";
import {
  TextField,
  Datagrid,
  Edit,
  EditButton,
  SimpleForm,
  TextInput,
  NumberInput,
  Create,
  SelectInput,
} from "react-admin";
import { useWatch } from "react-hook-form";
import { ListWithFilter } from "../components/ListWithFilter";

const NameOptions = [
  { id: "submit_type", name: "submit type" },
  // { id: "unknown", name: "test" }, // Example of linked input for future options
];
const ValueFieldValue = {
  submit_type: [
    { id: "team", name: "team" },
    { id: "individual", name: "individual" },
  ],
  // unknown: [{ id: "test", name: "test" }],
};

const exerciseSettingsList = () => (
  <ListWithFilter
    filters={[
      { label: "Cohort Module ID", source: "cohort_module_id" },
      { label: "Module ID", source: "module_id" },
      { label: "Exercise ID", source: "exercise_id" },
    ]}
  >
    <Datagrid>
      <EditButton />
      {/* <ReferenceField label="Cohort module id" source="id" reference="cohort_modules" perPage={300}> */}
      <TextField source="cohort_module_id" />
      <TextField source="module_id" />
      {/* </ReferenceField> */}
      <TextField source="exercise_id" label="Exercise id" />
      <TextField source="name" label="Name" />
      <TextField source="value" label="value" />
    </Datagrid>
  </ListWithFilter>
);

const ValueInput = props => {
  // const { values } = useFormState();
  const name = useWatch({ name: "name" });

  return <SelectInput choices={name ? ValueFieldValue[name] : []} {...props} />;
};

const UpdateFields = () => (
  <SimpleForm>
    <NumberInput
      label="Cohort module id"
      source="cohort_module_id"
      helperText="fill this for specific settings (one cohort)"
    />
    <TextInput
      label="Module id"
      source="module_id"
      helperText="fill this for general settings (all cohorts)"
    />
    <TextInput source="exercise_id" label="Exercise id" />
    <SelectInput source="name" choices={NameOptions} />
    <ValueInput source="value" label="value" />
  </SimpleForm>
);

const exerciseSettingsCreate = () => (
  <Create>
    <UpdateFields />
  </Create>
);

const exerciseSettingEdit = () => (
  <Edit>
    <UpdateFields />
  </Edit>
);

const exerciseSettings = {
  create: exerciseSettingsCreate,
  edit: exerciseSettingEdit,
  list: exerciseSettingsList,
};
export default exerciseSettings;
