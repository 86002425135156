import {
  List,
  TextField,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  SimpleForm,
  TextInput,
  Create,
  NumberField,
  ReferenceField,
  NumberInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";

const ModulesList = props => (
  <List sort={{ field: "created_at", order: "DESC" }}>
    <Datagrid>
      <EditButton />
      <TextField source="id" />
      <NumberField source="index" />
      <ReferenceField label="Level_id" source="level_id" reference="levels">
        <TextField source="id" />
      </ReferenceField>
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="time_commitment" />
      <TextField source="certificate_file" />
      <DateField source="created_at"></DateField>
    </Datagrid>
  </List>
);

const ModulesEdit = props => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" />
      <NumberInput source="index" />
      <ReferenceInput label="Level_id" source="level_id" reference="levels" perPage={100}>
        <SelectInput />
      </ReferenceInput>
      <TextInput source="name" />
      <TextInput multiline={true} source="description" />
      <TextInput source="time_commitment" />
      <TextInput source="certificate_file" />
    </SimpleForm>
  </Edit>
);
const ModulesCreate = props => (
  <Create>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput source="name" />
      <NumberInput source="index" />
      <ReferenceInput label="Level_id" source="level_id" reference="levels" perPage={100}>
        <SelectInput />
      </ReferenceInput>
      <TextInput multiline={true} source="description" />
      <TextInput source="time_commitment" />
      <TextInput source="certificate_file" />
    </SimpleForm>
  </Create>
);

const views = {
  create: ModulesCreate,
  edit: ModulesEdit,
  list: ModulesList,
};
export default views;
