import React from "react";

import {
  TextField,
  Datagrid,
  EmailField,
  Edit,
  EditButton,
  SimpleForm,
  AutocompleteInput,
  ReferenceField,
  SelectInput,
  ReferenceInput,
  Create,
  TextInput,
} from "react-admin";

import { Paper } from "@mui/material";

import { ListWithFilter } from "../components/ListWithFilter";

import InlineOptionField from "../components/InlineOptionField";

import BulkResultsUpdateButton from "../components/BulkChangeResult";

const ResultFields = [
  "core_status",
  "adv_status",
  "m1",
  "m2",
  "m3",
  "m4",
  "a_businessmodel",
  "a_innovationfinance",
  "a_designsprints",
  "a_uxdesign",
  "m1_retry",
  "m2_retry",
  "m3_retry",
  "m4_retry",
  "a_businessmodel_retry",
  "a_innovationfinance_retry",
  "a_designsprints_retry",
  "a_uxdesign_retry",
  "a_project_status",
];

const ResultsList = props => (
  <ListWithFilter
    {...props}
    filters={[
      { label: "user_id", source: "user_id" },
      { label: "cohort_id", source: "cohort_id" },
      { label: "user_email", source: "user#email@_ilike" },
    ]}
  >
    <Datagrid component={Paper} bulkActionButtons={<BulkResultsUpdateButton />}>
      <EditButton />
      <TextField source="user_id" label="user" sortable={false} />
      <ReferenceField label="email" source="user_id" reference="users" sortBy="user.email">
        <EmailField source="email" />
      </ReferenceField>
      <ReferenceField label="Cohort id" source="cohort_id" reference="company_cohorts">
        <TextField source="id" />
      </ReferenceField>
      {ResultFields.map((field, index) => (
        <InlineOptionField source={field} key={index} options={MODULE_RESULT} />
      ))}
    </Datagrid>
  </ListWithFilter>
);

const MODULE_RESULT = [
  { id: "completed", name: "Completed" },
  { id: "failed", name: "Failed" },
  { id: "failed-retries-expired", name: "Failed no retry" },
];

const resultsEdit = () => (
  <Edit>
    <SimpleForm>
      <ReferenceInput
        label="Cohort id"
        source="cohort_id"
        reference="company_cohorts"
        perPage={100}
      >
        <TextInput optionText="id" source="id" />
      </ReferenceInput>
      <AutocompleteInput source="m1" choices={MODULE_RESULT} />
      <AutocompleteInput source="m2" choices={MODULE_RESULT} />
      <AutocompleteInput source="m3" choices={MODULE_RESULT} />
      <AutocompleteInput source="m4" choices={MODULE_RESULT} />
      <AutocompleteInput source="m1_retry" choices={MODULE_RESULT} />
      <AutocompleteInput source="m2_retry" choices={MODULE_RESULT} />
      <AutocompleteInput source="m3_retry" choices={MODULE_RESULT} />
      <AutocompleteInput source="m4_retry" choices={MODULE_RESULT} />
      <AutocompleteInput source="core_status" choices={MODULE_RESULT} />
      <AutocompleteInput source="a_businessmodel" choices={MODULE_RESULT} />
      <AutocompleteInput source="a_innovationfinance" choices={MODULE_RESULT} />
      <AutocompleteInput source="a_designsprints" choices={MODULE_RESULT} />
      <AutocompleteInput source="a_uxdesign" choices={MODULE_RESULT} />
      <AutocompleteInput source="a_businessmodel_retry" choices={MODULE_RESULT} />
      <AutocompleteInput source="a_innovationfinance_retry" choices={MODULE_RESULT} />
      <AutocompleteInput source="a_designsprints_retry" choices={MODULE_RESULT} />
      <AutocompleteInput source="a_uxdesign_retry" choices={MODULE_RESULT} />
      <AutocompleteInput source="adv_status" choices={MODULE_RESULT} />
    </SimpleForm>
  </Edit>
);
const resultsCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput optionText="user_id" source="user_id" />
      <ReferenceInput
        label="Cohort id"
        source="cohort_id"
        reference="company_cohorts"
        perPage={300}
      >
        <SelectInput optionText="id" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

const views = {
  create: resultsCreate,
  edit: resultsEdit,
  list: ResultsList,
};

export default views;
