import React from "react";

import { FunctionField } from "react-admin";

export const AssignmentExerciseField = props => {
  return (
    <FunctionField {...props} render={record => `${record.module_id}/${record.exercise_id}`} />
  );
};

export default AssignmentExerciseField;
