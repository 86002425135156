import React from "react";
import {
  TextField,
  Datagrid,
  DateField,
  EmailField,
  Edit,
  EditButton,
  SimpleForm,
  TextInput,
  BooleanInput,
  BooleanField,
  Create,
  ReferenceInput,
  SelectInput,
  required,
  BulkDeleteButton,
} from "react-admin";
import BulkAddCohortUser from "../components/BulkAddCohortUser";
import NameField from "../components/NameField";
import { ListWithFilter } from "../components/ListWithFilter";

const UserBulkActions = () => (
  <>
    <BulkAddCohortUser label="Add Cohort" />
    {/* default bulk delete action */}
    <BulkDeleteButton />
  </>
);

const UsersList = () => (
  <ListWithFilter
    filters={[
      { label: "id", source: "id" },
      { label: "email", source: "email" },
      { label: "first_name", source: "first_name" },
      { label: "last_name", source: "last_name" },
      { label: "alternate_email", source: "alternate_email" },
      { label: "is_admin_role", source: "is_admin_role" },
      { label: "is_grader_role", source: "is_grader_role" },
    ]}
  >
    <Datagrid bulkActionButtons={<UserBulkActions />}>
      <EditButton />
      <TextField source="id" />
      <EmailField source="email" />
      <EmailField source="alternate_email" />
      <NameField />
      <DateField source="created_at" />
      <BooleanField source="is_admin_role" />
      <BooleanField source="is_grader_role" />
    </Datagrid>
  </ListWithFilter>
);

const validateUserCreation = values => {
  const errors = {};

  if (!values.first_name) errors.first_name = "First name is required";
  if (!values.last_name) errors.last_name = "First name is required";

  return errors;
};

const userCreate = () => (
  <Create>
    <SimpleForm validate={validateUserCreation}>
      <TextInput
        source="email"
        validate={required("Needs an email!")}
        parse={value => {
          return value.toLowerCase();
        }}
      />
      <TextInput
        source="alternate_email"
        parse={value => {
          return value.toLowerCase();
        }}
      />
      <TextInput source="first_name" />
      <TextInput source="last_name" />
      <ReferenceInput
        label="Cohort id"
        source="cohort_id"
        reference="company_cohorts"
        perPage={100}
      >
        <SelectInput optionText="id" validate={required("Needs a cohort!")} />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

const userEdit = () => (
  <Edit>
    <SimpleForm validate={validateUserCreation}>
      <TextInput source="email" parse={value => value.toLowerCase()} />
      <TextInput source="alternate_email" parse={value => value.toLowerCase()} />
      <TextInput source="first_name" />
      <TextInput source="last_name" />
      <BooleanInput source="is_admin_role" />
      <BooleanInput source="is_grader_role" />
    </SimpleForm>
  </Edit>
);

const views = {
  create: userCreate,
  edit: userEdit,
  list: UsersList,
};
export default views;
