import React, { useEffect } from "react";
import { useQuery } from "@sparkademy/app-common/utils/useQuery";
import { setCookie } from "@sparkademy/app-common/utils/cookie";

const Login: React.FC = params => {
  function isDev() {
    return window.location.hostname.includes("local.sparkademy.com");
  }

  // get token from query string
  const query = useQuery();
  const token = query.get("token");

  // set token in auth_token cookie
  useEffect(() => {
    if (token) {
      console.log(`setting token ${token}`);
      setCookie("auth_token", token, 30);
      document.location.href = "/";
    } else {
      const prod = "https://enablement.sparkademy.com";
      const dev = "http://local.sparkademy.com:8000";
      window.location.href = (isDev() ? dev : prod) + `/login?next=${window.location.href}`;
    }
  }, [token]);

  return <p>redirecting...</p>;
};

export default Login;
