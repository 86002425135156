import React, { useState } from "react";
import { useListContext } from "react-admin";
import { Select, Box, Button, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

export const Filter = ({ filters = [] }) => {
  const [currentFilterSource, setCurrentFilterSource] = useState(
    filters.length ? filters[0].source : "[none]"
  );
  const [currentFilterValue, setCurrentFilterValue] = useState("");
  const { filterValues, setFilters } = useListContext();

  const getLabelFromSource = source => {
    const item = filters.find(item => item.source === source);
    return item ? item.label : "";
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box>
        <span style={{ marginRight: "10px" }}>Add Filter By:</span>
        <Select
          onChange={e => setCurrentFilterSource(e.target.value)}
          native
          sx={{ height: "40px" }}
          value={currentFilterSource}
        >
          {filters.map(f => (
            <option key={f.source} value={f.source}>
              {f.label}
            </option>
          ))}
        </Select>

        <TextField
          sx={{ mx: "10px", my: 0 }}
          size="small"
          variant="outlined"
          value={currentFilterValue}
          onChange={e => setCurrentFilterValue(e.target.value)}
          onKeyPress={e => {
            // add filter when hitting enter
            if (e.key === "Enter") {
              setFilters({
                ...filterValues,
                [currentFilterSource]: currentFilterValue,
              });
              setCurrentFilterValue("");
            }
          }}
        />

        <Button
          sx={{ height: "40px" }}
          variant="outlined"
          color="primary"
          onClick={() => {
            setFilters({ ...filterValues, [currentFilterSource]: currentFilterValue });
            setCurrentFilterValue("");
          }}
        >
          Add
        </Button>
      </Box>

      <Box sx={{ my: "10px" }}>
        {Object.keys(filterValues).map(k => (
          <Box sx={{ display: "inline-block" }}>
            <span style={{ marginRight: "10px" }}>
              {getLabelFromSource(k)}: <b>{filterValues[k]}</b>
            </span>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() =>
                setFilters({
                  ...filterValues,
                  [k]: undefined,
                })
              }
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
