import { TextField, Datagrid, DateField, ReferenceField, BooleanField } from "react-admin";
import { ListWithFilter } from "../components/ListWithFilter";

const publicUsersList = props => (
  <ListWithFilter
    filters={[
      { label: "id", source: "id" },
      { label: "email", source: "email" },
      { label: "company_id", source: "company_id" },
      { label: "cohort_id", source: "cohort_id" },
    ]}
  >
    <Datagrid>
      {/* <EditButton /> */}
      <TextField source="id" />
      <TextField source="email" />
      <ReferenceField label="Company id" source="company_id" reference="companies">
        <TextField source="id" />
      </ReferenceField>
      <BooleanField source="is_role_method_kit" />
      <BooleanField source="is_role_sparkcheck" />
      {/* <ReferenceField label="Cohort id" source="cohort_id" reference="company_cohorts"> */}
      <TextField source="cohort_id" label="Cohort id" />
      {/* </ReferenceField> */}
      <DateField source="created_at" />
    </Datagrid>
  </ListWithFilter>
);

// const publicUsersEdit = props => (
//   <Edit >
//     <SimpleForm>
//       <TextInput source="email" />
//       <ReferenceInput label="Company id" source="company_id" reference="companies" perPage={100}>
//         <SelectInput />
//       </ReferenceInput>
//       <BooleanInput source="is_role_method_kit" />
//       <BooleanInput source="is_role_sparkcheck" />
//       {/* <ReferenceInput label="Cohort id" source="cohort_id" reference="company_cohorts"> */}
//       <TextInput source="cohort_id" label="Cohort id" />
//       {/* </ReferenceInput> */}
//     </SimpleForm>
//   </Edit>
// );

// const publicUsersCreate = props => (
//   <Create >
//     <SimpleForm>
//       <TextInput source="email" />
//       <ReferenceInput label="Company id" source="company_id" reference="companies" perPage={100}>
//         <SelectInput />
//       </ReferenceInput>
//       <BooleanInput source="is_role_method_kit" />
//       <BooleanInput source="is_role_sparkcheck" />
//       {/* <ReferenceInput label="Cohort id" source="cohort_id" reference="company_cohorts"> */}
//       <TextInput source="cohort_id" label="Cohort id" />
//       {/* </ReferenceInput> */}
//     </SimpleForm>
//   </Create>
// );

const views = {
  // create: publicUsersCreate,
  // edit: publicUsersEdit,
  list: publicUsersList,
};

export default views;
