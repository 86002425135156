import React from "react";
import {
  TextField,
  DateField,
  Datagrid,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  ReferenceField,
} from "react-admin";

import NameField from "../components/NameField";
import { ListWithFilter } from "../components/ListWithFilter";

const WorkshopEnrollmentList = props => (
  <ListWithFilter
    filters={[
      { label: "user_id", source: "user_id" },
      { label: "session_id", source: "session_id" },
      { label: "workshop_id", source: "workshop_id" },
    ]}
  >
    <Datagrid>
      <TextField source="id" />
      <DateField source="date_created" />
      <ReferenceField label="Learner" source="user_id" reference="users">
        <NameField />
      </ReferenceField>
      <TextField source="session_id" />
      <TextField source="workshop_id" />
    </Datagrid>
  </ListWithFilter>
);

const WorkshopEnrollmentEdit = props => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput source="user_id" />
      <TextInput source="session_id" />
      <TextInput source="workshop_id" />
    </SimpleForm>
  </Edit>
);

const WorkshopEnrollmentCreate = props => {
  return (
    <Create>
      <SimpleForm>
        <TextInput source="user_id" />
        <TextInput source="session_id" />
        <TextInput source="workshop_id" />
      </SimpleForm>
    </Create>
  );
};

const views = {
  list: WorkshopEnrollmentList,
  edit: WorkshopEnrollmentEdit,
  create: WorkshopEnrollmentCreate,
};

export default views;
