import React from "react";
import {
  TextField,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  SimpleForm,
  TextInput,
  ReferenceField,
  Create,
} from "react-admin";
import NameField from "../components/NameField";
import AssignmentExerciseField from "../components/AssignmentExerciseField";
import { ListWithFilter } from "../components/ListWithFilter";

const TeamUsersList = props => (
  <ListWithFilter
    filters={[
      { label: "Member ID", source: "user_id" },
      { label: "Team ID", source: "team_id" },
    ]}
    sort={{ field: "created_at", order: "DESC" }}
  >
    <Datagrid>
      <EditButton />
      <ReferenceField label="Member Name" source="user_id" reference="users">
        <NameField />
      </ReferenceField>
      <ReferenceField label="Team ID" source="team_id" reference="teams">
        <TextField source="id" />
      </ReferenceField>

      <ReferenceField label="Assignment Exercise" source="team_id" reference="teams" link={false}>
        <ReferenceField
          source="assignment_exercise_id"
          reference="assignment_exercises"
          link={false}
        >
          <AssignmentExerciseField />
        </ReferenceField>
      </ReferenceField>

      <DateField source="created_at" />
    </Datagrid>
  </ListWithFilter>
);

const TeamUsersEdit = props => (
  <Edit>
    <SimpleForm>
      <TextInput label="User ID" source="user_id" sx={{ width: 350 }} />
      <TextInput label="Team ID" source="team_id" />
    </SimpleForm>
  </Edit>
);

const TeamUsersCreate = props => (
  <Create>
    <SimpleForm>
      <TextInput source="user_id" />
      <TextInput source="team_id" />
    </SimpleForm>
  </Create>
);

const views = {
  list: TeamUsersList,
  edit: TeamUsersEdit,
  create: TeamUsersCreate,
};

export default views;
