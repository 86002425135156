import {
  List,
  TextField,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  SimpleForm,
  TextInput,
  Create,
} from "react-admin";

const LevelsList = props => (
  <List sort={{ field: "created_at", order: "DESC" }}>
    <Datagrid>
      <EditButton />
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="time_commitment" />
      <TextField source="certificate_file" />
      <DateField source="created_at"></DateField>
    </Datagrid>
  </List>
);

const LevelsEdit = props => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput source="name" />
      <TextInput multiline={true} source="description" />
      <TextInput source="time_commitment" />
      <TextInput source="certificate_file" />
    </SimpleForm>
  </Edit>
);
const LevelsCreate = props => {
  return (
    <Create>
      <SimpleForm>
        <TextInput source="id" />
        <TextInput source="name" />
        <TextInput multiline={true} source="description" />
        <TextInput source="time_commitment" />
        <TextInput source="certificate_file" />
      </SimpleForm>
    </Create>
  );
};

const views = {
  create: LevelsCreate,
  edit: LevelsEdit,
  list: LevelsList,
};
export default views;
