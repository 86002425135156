import { setCookie } from "@sparkademy/app-common/utils/cookie";
import Cookies from "js-cookie";
import { AuthProvider } from "react-admin";

export const getAuthToken = () => Cookies.get("auth_token");

export const AdminAuthProvider: AuthProvider = {
  // authentication
  login: () => {
    let token = getAuthToken();
    return token ? Promise.resolve() : Promise.reject();
  },
  checkError: error => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("authToken");
      return Promise.reject({ redirectTo: "/login" });
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: () => {
    let token = getAuthToken();
    return token ? Promise.resolve() : Promise.reject();
  },
  logout: () => {
    setCookie("auth_token", "", -3);
    return Promise.resolve();
  },
  // authorization
  getPermissions: () => {
    // return Promise.resolve(JSON.parse(localStorage.getItem("user")!).role);
    return Promise.resolve();
  },
};
