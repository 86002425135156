import { defaultTheme } from "react-admin";
import merge from "lodash/merge";

export const theme = merge({}, defaultTheme, {
  overrides: {
    MuiTablePagination: {
      spacer: {
        flex: "none",
      },

      //   root: {
      //     bottom: 0, // <-- KEY
      //     zIndex: 1000,
      //     position: "sticky",
      //     backgroundColor: "white",
      //     opacity: 1,
      //   },
      toolbar: { bottom: 0, position: "sticky", backgroundColor: "white", left: 0 },
    },
    RaList: {
      content: { overflowX: "auto", width: "80vw", maxHeight: "80vh" },
    },
    MuiToolbar: {
      root: {
        backgroundColor: "white",
        opacity: 1,
      },
      backgroundColor: "red",
      opacity: 1,
    },
  },
});
