import React, { useState } from "react";
import {
  Button,
  SaveButton,
  useCreate,
  useNotify,
  ReferenceInput,
  SelectInput,
  useRecordContext,
  Form,
} from "react-admin";
import PeopleIcon from "@mui/icons-material/People";
import { useFormState } from "react-hook-form";

import IconCancel from "@mui/icons-material/Cancel";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

function QuickResultCreateButton(props) {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate();
  const notify = useNotify();
  const record = useRecordContext(props);

  // const form = useForm();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    values.user_id = record.id;
    console.log("Submit vals:", values);
    create(
      "results",
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          // form.change("user_id", data.user_id);
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  const dialogProps = {
    handleSubmit,
    handleCloseClick,
    loading,
    showDialog,
  };

  return (
    <>
      <Button onClick={handleClick} label="Add cohort" variant="contained">
        <PeopleIcon />
      </Button>

      <CohortSelectDialog {...dialogProps} />
    </>
  );
}

export const CohortSelectDialog = props => (
  <Dialog
    fullWidth
    open={props.showDialog}
    onClose={props.handleCloseClick}
    aria-label="add cohort"
  >
    <DialogTitle>Add User to Cohort</DialogTitle>
    <Form onSubmit={props.handleSubmit}>
      <FormContent {...props} />
    </Form>
  </Dialog>
);

const FormContent = props => {
  const { pristine, saving } = useFormState();
  return (
    <>
      <DialogContent>
        <ReferenceInput label="Cohort id" source="cohort_id" reference="company_cohorts">
          <SelectInput optionText="id" />
        </ReferenceInput>
      </DialogContent>
      <DialogActions>
        <Button label="ra.action.cancel" onClick={props.handleCloseClick} disabled={props.loading}>
          <IconCancel />
        </Button>
        <SaveButton pristine={pristine} saving={saving} disabled={props.loading} />
      </DialogActions>
    </>
  );
};

export default QuickResultCreateButton;
