// Initialize the dataProvider before rendering react-admin resources.
import React, { useState, useEffect } from "react";
import buildHasuraProvider from "ra-data-hasura";
import { Admin, Resource } from "react-admin";
import { AdminAuthProvider, getAuthToken } from "./AdminAuthProvider";
import { createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import jwtDecode from "jwt-decode";
import Login from "./pages/Login.tsx";
import CustomLayout from "./Layout";

import usersDataProvider from "./data/usersDataProvider";
import gradersDataProvider from "./data/gradersDataProvider";

import results from "./Views/results";
import users from "./Views/users";
import levels from "./Views/levels";
import modules from "./Views/modules";
import companies from "./Views/companies";
import cohorts from "./Views/companyCohorts";
import cohortModules from "./Views/cohortModules";
import publicUsers from "./Views/publicUsers";
import exerciseSettings from "./Views/cohortExerciseSettings";
import teams from "./Views/teams";
import teamUsers from "./Views/teamUsers";
import skillWorkshopSessionTeams from "./Views/skillWorkshopSessionTeams";
import skillWorkshopResource from "./Views/skillWorkshopResource";
import skillWorkshopEnrollments from "./Views/skillWorkshopEnrollments";
import graders from "./Views/graders";

import { theme } from "./theme";

window.__DEV__ = false;

const App = () => {
  const token = getAuthToken();

  const [dataProvider, setDataProvider] = useState(null);
  useEffect(() => {
    const buildDataProvider = async () => {
      const authLink = setContext((_, { headers }) => ({
        headers: {
          ...headers,
          ...(token ? { Authorization: `Bearer ${token}` } : undefined),
        },
      }));

      const httpLink = createHttpLink({
        uri: process.env.REACT_APP_HASURA_URL || "https://hasura.sparkademy.com/v1/graphql",
      });

      const provider = await buildHasuraProvider({
        clientOptions: {
          link: authLink.concat(httpLink),
          connectToDevTools: true,
        },
      });

      const customProvider = (type, resource, params) => {
        console.log({ type, resource, params });
        switch (resource) {
          case "users":
            return usersDataProvider(type, resource, params, provider);
          case "graders":
            return gradersDataProvider(type, resource, params, provider);
          default:
            // fallback to the default implementation
            return provider(type, resource, params);
        }
      };

      setDataProvider(() => customProvider);
    };
    buildDataProvider();
  }, [token]);
  if (!token) {
    return <Login />;
  }
  const claims = jwtDecode(token);

  if (claims["role"] !== "admin") {
    return <Login />;
  }

  if (!dataProvider) {
    return <p>Loading...</p>;
  }

  return (
    <Admin
      layout={CustomLayout}
      theme={theme}
      dataProvider={dataProvider}
      loginPage={Login}
      authProvider={AdminAuthProvider}
    >
      <Resource name="users" {...users} />
      <Resource name="results" {...results} />
      <Resource name="levels" {...levels} />
      <Resource name="modules" {...modules} />
      <Resource name="companies" {...companies} />
      <Resource name="company_cohorts" {...cohorts} />
      <Resource name="cohort_modules" {...cohortModules} />
      <Resource name="public_users" {...publicUsers} />
      <Resource name="cohort_exercise_settings" {...exerciseSettings} />
      <Resource options={{ label: "Assignment Team" }} name="teams" {...teams} />
      <Resource options={{ label: "Assignment Team Users" }} name="team_users" {...teamUsers} />
      <Resource name="assignment_exercises" />
      <Resource
        name="skill_workshop_session_teams"
        recordRepresentation={record => `${record.session_id} - ${record.title}`}
        options={{ label: "Workshop Teams" }}
        {...skillWorkshopSessionTeams}
      />
      <Resource
        name="skill_workshop_resources"
        {...skillWorkshopResource}
        options={{ label: "Workshop Team Resources" }}
      />
      <Resource
        options={{ label: "Workshop Enrollment" }}
        name="skill_workshop_session_enrollments"
        {...skillWorkshopEnrollments}
      />
      <Resource name="graders" {...graders} />
    </Admin>
  );
};
export default App;
