import React from "react";
import {
  TextField,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  SimpleForm,
  TextInput,
  Create,
  required,
  useRecordContext,
  ReferenceField,
} from "react-admin";
import NameField from "../components/NameField";
import { ListWithFilter } from "../components/ListWithFilter";

const FormattedListField = props => {
  const record = useRecordContext(props);
  if (!record.allowed_cohorts) return null;

  // check if it's a string
  if (typeof record.allowed_cohorts === "string") {
    return <span>{record.allowed_cohorts}</span>;
  }

  // check if it's an array
  if (record.allowed_cohorts instanceof Array) {
    return <span>{record.allowed_cohorts.join(", ")}</span>;
  }

  return null;
};

const GraderList = () => (
  <ListWithFilter
    filters={[
      { label: "id", source: "id" },
      { label: "user_id", source: "user_id" },
    ]}
  >
    <Datagrid bulkActionButtons={false}>
      <EditButton />
      <TextField source="user_id" />

      <ReferenceField label="User Name" source="user_id" reference="users">
        <NameField />
      </ReferenceField>
      {/* <NameField /> */}
      <DateField source="created_at" />
      <FormattedListField source="allowed_cohorts" />
    </Datagrid>
  </ListWithFilter>
);

const validateGraderCreation = values => {
  const errors = {};

  if (!values.first_name) errors.first_name = "First name is required";
  if (!values.last_name) errors.last_name = "First name is required";
  if (!values.allowed_cohorts) errors.allowed_cohorts = "At least one cohort is required";

  return errors;
};
const validateGraderEditing = values => {
  const errors = {};
  console.log({ values });
  if (!values.allowed_cohorts || values.allowed_cohorts.length === 0)
    errors.allowed_cohorts = "At least one cohort is required";

  return errors;
};

const graderCreate = () => (
  <Create
    transform={data => {
      data["allowed_cohorts"] =
        "{" + (data["allowed_cohorts"] ? data["allowed_cohorts"] : "") + "}";
      return data;
    }}
  >
    <SimpleForm validate={validateGraderCreation}>
      <TextInput source="email" validate={required("Needs an email!")} />
      <TextInput source="first_name" />
      <TextInput source="last_name" />
      <TextInput
        source="allowed_cohorts"
        parse={value => (value ? value.split(",") : [])}
        validate={required("requires at least one cohort")}
      />
    </SimpleForm>
  </Create>
);

const graderEdit = () => (
  <Edit
    transform={data => {
      data["allowed_cohorts"] =
        "{" + (data["allowed_cohorts"] ? data["allowed_cohorts"] : "") + "}";
      return data;
    }}
  >
    <SimpleForm validate={validateGraderEditing}>
      <TextInput source="user_id" />
      <TextInput
        source="allowed_cohorts"
        parse={value => (value ? value.split(",") : [])}
        validate={required("requires at least one cohort")}
      />
    </SimpleForm>
  </Edit>
);

const views = {
  create: graderCreate,
  edit: graderEdit,
  list: GraderList,
};
export default views;
