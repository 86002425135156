import React, { Fragment, useState } from "react";
import { Button, useUpdateMany, useNotify, useUnselectAll, Form } from "react-admin";

import IconCancel from "@mui/icons-material/Cancel";
import IconSave from "@mui/icons-material/Save";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const RESULTS_COLUMNS = [
  "m1",
  "m2",
  "m3",
  "m4",
  "m1_retry",
  "m2_retry",
  "m3_retry",
  "m4_retry",
  "core_status",
  "a_businessmodel",
  "a_innovationfinance",
  "a_designsprints",
  "a_uxdesign",
  "a_businessmodel_retry",
  "a_innovationfinance_retry",
  "a_designsprints_retry",
  "a_uxdesign_retry",
  "a_project_status",
  "adv_status",
];

const MODULE_RESULT = [
  { id: "completed", label: "Completed" },
  { id: "failed", label: "Failed" },
  { id: "failed-retries-expired", label: "Failed no retry" },
];

const BulkResultsUpdateButton = ({ selectedIds }) => {
  const [open, setOpen] = useState(false);
  const [selectedColumn, setColumnHandler] = useState(RESULTS_COLUMNS[0]);
  const [newValue, setNewValueHandler] = useState(MODULE_RESULT[0]);
  const notify = useNotify();
  const unselectAll = useUnselectAll("results");
  const [updateMany, { loading }] = useUpdateMany();
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    console.log("Submitted", { selectedIds, selectedColumn });
    updateMany(
      "results",
      {
        ids: selectedIds,
        data: { [selectedColumn]: newValue.id },
      }, // THIS WAS IMPOSSIBLE TO FIND [key] : value makes no sense lol
      {
        onSuccess: () => {
          notify("Results updated");
          unselectAll();
          setTimeout(() => window.location.reload(), 1000);
        },
        onError: _ => notify("Error: results not updated", "warning"),
      }
    );
    setOpen(false);
  };

  const dialogProps = {
    handleSubmit: handleConfirm,
    handleCloseClick: handleDialogClose,
    loading,
    showDialog: open,
    setColumnHandler,
    setNewValueHandler,
  };

  return (
    <Fragment>
      <Button label="Update results" onClick={handleClick} />
      <ResultUpdateDialog {...dialogProps} />
    </Fragment>
  );
};

const ResultUpdateDialog = props => {
  return (
    <Dialog
      fullWidth
      open={props.showDialog}
      onClose={props.handleCloseClick}
      aria-label="add cohort"
    >
      <DialogTitle>Add Cohort</DialogTitle>
      <Form onSubmit={props.handleSubmit}>
        <FormContent
          handleSubmit={props.handleSubmit}
          handleCloseClick={props.handleCloseClick}
          setColumnHandler={props.setColumnHandler}
          setNewValueHandler={props.setNewValueHandler}
        />
      </Form>
    </Dialog>
  );
};

const FormContent = props => {
  // const { isSubmitting } = useFormState();

  return (
    <>
      <DialogContent>
        <Autocomplete
          disablePortal
          id=""
          options={RESULTS_COLUMNS}
          onInputChange={(_, val) => props.setColumnHandler(val)}
          renderInput={params => <TextField {...params} label="Column" />}
        />
        <Autocomplete
          disablePortal
          id="input-select"
          options={MODULE_RESULT}
          onChange={(_, newValue) => {
            props.setNewValueHandler(newValue);
          }}
          renderInput={params => <TextField {...params} label="New Value" />}
        />
      </DialogContent>
      <DialogActions>
        <Button label="ra.action.cancel" onClick={props.handleCloseClick} disabled={props.loading}>
          <IconCancel />
        </Button>
        <Button label="ra.action.save" type="submit" disabled={props.loading}>
          <IconSave />
        </Button>
        {/* <SaveButton onClick={props.handleSubmit} disabled={false}/> */}
      </DialogActions>
    </>
  );
};

export default BulkResultsUpdateButton;
