import {
  TextField,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  SimpleForm,
  TextInput,
  Create,
  ReferenceField,
  NumberField,
  ReferenceInput,
  SelectInput,
  UrlField,
  NumberInput,
} from "react-admin";
import { ListWithFilter } from "../components/ListWithFilter";

const CohortsModulesList = props => (
  <ListWithFilter
    filters={[
      { label: "id", source: "id" },
      { label: "cohort_id", source: "cohort_id" },
      { label: "module_id", source: "module_id" },
      { label: "materials_url", source: "materials_url" },
      { label: "feedback_url", source: "feedback_url" },
    ]}
    sort={{ field: "created_at", order: "DESC" }}
  >
    <Datagrid>
      <EditButton />
      <TextField source="id" />
      <ReferenceField label="Cohort id" source="cohort_id" reference="company_cohorts">
        <TextField source="id" />
      </ReferenceField>
      <ReferenceField label="Module id" source="module_id" reference="modules">
        <TextField source="id" />
      </ReferenceField>
      <NumberField source="index" />
      {/* Timezone UTC to avoid confusion. If not set, converts from UTC to current time zone, possibly changing the date displayed */}
      <DateField options={{ timeZone: "UTC" }} source="start_on" />
      <DateField options={{ timeZone: "UTC" }} source="assessment_start_on" />
      <DateField options={{ timeZone: "UTC" }} source="end_on" />
      <UrlField source="materials_url" />
      <UrlField source="feedback_url" />
    </Datagrid>
  </ListWithFilter>
);

const CohortsModulesEdit = props => (
  <Edit>
    <SimpleForm>
      <ReferenceInput
        label="Cohort id"
        source="cohort_id"
        reference="company_cohorts"
        perPage={100}
      >
        <SelectInput optionText="id" />
      </ReferenceInput>
      <ReferenceInput label="Module id" source="module_id" reference="modules" perPage={100}>
        <SelectInput optionText="id" />
      </ReferenceInput>
      <NumberInput source="index" />
      <DateInput source="start_on" />
      <DateInput source="assessment_start_on" />
      <DateInput source="end_on" />
      <TextInput source="materials_url" />
      <TextInput source="feedback_url" />
    </SimpleForm>
  </Edit>
);

const CohortsModulesCreate = props => (
  <Create>
    <SimpleForm>
      <ReferenceInput
        label="Cohort id"
        source="cohort_id"
        reference="company_cohorts"
        perPage={150}
      >
        <SelectInput optionText="id" />
      </ReferenceInput>
      <ReferenceInput label="Module id" source="module_id" reference="modules" perPage={150}>
        <SelectInput optionText="id" />
      </ReferenceInput>
      <NumberInput source="index" />
      <DateInput source="start_on" />
      <DateInput source="assessment_start_on" />
      <DateInput source="end_on" />
      <TextInput source="materials_url" />
      <TextInput source="feedback_url" />
    </SimpleForm>
  </Create>
);

const views = {
  create: CohortsModulesCreate,
  edit: CohortsModulesEdit,
  list: CohortsModulesList,
};
export default views;
